<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
    >
        <h2 class="mb-12 text-center md:text-left">
            {{ data.heading }}
        </h2>
        <div
            class="grid grid-cols-1 gap-[45px] md:grid-cols-2 md:px-0 lg:grid-cols-3"
        >
            <TradeShowCard
                v-for="(item, key) in sortedTradeShows"
                :key="'trade-show-' + key"
                :trade-show="item"
            />
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import BlockContainer from '~/components/BlockContainer.vue';
import TradeShowGridType from '~/types/TradeShowGridType';
import TradeShowCard from '~/components/page-building/tradeshow-grid/TradeShowCard.vue';
import TradeShowType from '~/types/TradeShowType';
import { compare } from '~/utils/helpers';

const props = defineProps<{
    data: TradeShowGridType;
}>();

const dateInPast = (date: string) => {
    const today = new Date();
    const thisDate = new Date(date);
    return thisDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
};

const tradeShows = computed<TradeShowType[]>(() => {
    return props.data.items.filter((item: TradeShowType) => {
        return !dateInPast(item.startDate);
    });
});

const sortedTradeShows = computed<TradeShowType[]>(() => {
    return tradeShows.value.sort((a, b) => {
        return compare(a.startDate, b.startDate);
    });
});
</script>
