export default function useDateSplitter() {
    const getDay = (date: string) => {
        if (!date) {
            return '';
        }
        return new Date(date).getDate();
    };
    const getMonth = (date: string) => {
        if (!date) {
            return '';
        }
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        return months[new Date(date).getMonth()];
    };
    const getYear = (date: string) => {
        if (!date) {
            return '';
        }
        return new Date(date).getFullYear();
    };
    return {
        getDay,
        getMonth,
        getYear,
    };
}
