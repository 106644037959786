<template>
    <div
        v-editable="tradeShow.vEditable"
        class="group flex flex-col justify-between rounded-lg bg-white text-left drop-shadow-[0_0_8px_rgba(20,20,20,0.1)]"
    >
        <div class="rounded-t-lg bg-light-blue">
            <div class="flex flex-col gap-2 py-10 text-center">
                <h3>{{ month }}</h3>
                <div class="font-accent text-[64px] leading-none">
                    {{ days }}
                </div>
                <div class="text-[30px]">{{ year }}</div>
            </div>
        </div>
        <div class="mb-4 flex flex-col gap-3 p-6">
            <div class="text-base text-drip">
                {{ tradeShow.location }}
            </div>
            <div class="text-[20px] font-bold text-grind">
                {{ tradeShow.title }}
            </div>
            <div v-if="tradeShow.subtitle" class="text-base text-grind">
                {{ tradeShow.subtitle }}
            </div>
            <a
                v-if="tradeShow.link && tradeShow.link.url"
                :href="tradeShow.link.url"
                target="_blank"
            >
                <div class="flex items-center gap-2">
                    Trade show website
                    <Icon name="arrow-up-right-from-square-solid" />
                </div>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
import TradeShowType from '~/types/TradeShowType';
import useDateSplitter from '~/composables/useDateSplitter';

const props = defineProps<{
    tradeShow: TradeShowType;
}>();

const { getDay, getMonth, getYear } = useDateSplitter();

const days = computed(() => {
    const startDay = getDay(props.tradeShow.startDate);
    const endDay = getDay(props.tradeShow.endDate);
    if (!props.tradeShow.endDate || startDay === endDay) {
        return startDay;
    } else {
        return startDay + ' - ' + endDay;
    }
});
const month = computed(() => {
    const startMonth = getMonth(props.tradeShow.startDate);
    const endMonth = getMonth(props.tradeShow.endDate);
    if (startMonth !== endMonth) {
        return startMonth + ' - ' + endMonth;
    }
    return startMonth;
});
const year = computed(() => {
    const startYear = getYear(props.tradeShow.startDate);
    const endYear = getYear(props.tradeShow.endDate);
    if (startYear !== endYear) {
        return startYear + ' - ' + endYear;
    }
    return startYear;
});
</script>
